@charset "UTF-8";
[data-v-665484b6] .el-input__inner {
  background-color: transparent !important;
  border: 0;
  font-family: "PingFangSt";
  color: #fff;
  font-size: 1vw;
  font-style: italic;
  text-shadow: 0 0 12px rgba(0, 106, 255, 0.8);
  padding: 5px;
  font-weight: bold;
  padding-right: 35px;
  white-space: nowrap;
  /* 禁止换行 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}
[data-v-665484b6] .el-select__caret {
  color: #fff !important;
  font-size: 1.563vw !important;
}
[data-v-665484b6] .el-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#app[data-v-665484b6] {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
#app .main[data-v-665484b6] {
  width: 100vw;
  height: 100vh;
  background: url(../../static/img/backgroundOld.1d1f8e61.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 5.208vw;
}
#app .main .fullScreen[data-v-665484b6] {
  position: fixed;
  top: 1.563vw;
  right: 2.344vw;
  font-size: 18px;
  color: #E3F3FF;
}
#app .main > div[data-v-665484b6] {
  position: relative;
  z-index: 2;
}
#app .main .header[data-v-665484b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #CDD6e3;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 3.125vw;
  z-index: 999999;
}
#app .main .header .dateTime[data-v-665484b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
#app .main .header .dateTime span[data-v-665484b6]:first-child {
  font-size: 0.938vw;
}
#app .main .header .dateTime span[data-v-665484b6]:last-child {
  font-size: 0.625vw;
}
#app .main .header .statistics[data-v-665484b6] {
  width: 10.677vw;
  height: 3.125vw;
  margin-left: 3.125vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#app .main .header .statistics > div[data-v-665484b6]:first-child {
  width: 3.125vw;
  height: 3.125vw;
}
#app .main .header .statistics > div:first-child img[data-v-665484b6] {
  width: 100%;
  height: 100%;
}
#app .main .header .statistics > div[data-v-665484b6]:last-child {
  width: 7.552vw;
  height: 3.125vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
#app .main .header .statistics > div:last-child span[data-v-665484b6]:first-child {
  font-size: 0.729vw;
  color: #E3F3FF;
}
#app .main .header .statistics > div:last-child span[data-v-665484b6]:last-child {
  font-size: 1.458vw;
}
#app .main .header .statistics > div:last-child span:last-child span[data-v-665484b6] {
  font-size: 0.573vw;
  color: #E3F3FF;
}
#app .main .header .switchProject[data-v-665484b6] {
  width: 13.021vw;
  height: 2.083vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 3.125vw;
}
#app .main .body[data-v-665484b6] {
  height: calc(100% - 3.125vw);
  padding: 1.042vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 9;
}
#app .main .body > div[data-v-665484b6]:first-child {
  margin-right: 1.3889vh;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  z-index: 9999;
}
#app .main .body > div:first-child > div[data-v-665484b6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#app .main .body > div:first-child > div[data-v-665484b6]:last-child {
  margin-bottom: 0;
}
#app .main .body > div[data-v-665484b6]:nth-child(2) {
  width: 50%;
  margin-right: 1.3889vh;
}
#app .main .body > div:nth-child(2) .earthMap[data-v-665484b6] {
  position: relative;
  z-index: 888;
  background-position: center;
  width: 100%;
  height: 100%;
  margin-bottom: 1.3889vh;
}
#app .main .body > div:nth-child(2) .earthMap #earthMap[data-v-665484b6],
#app .main .body > div:nth-child(2) .earthMap #wordMap[data-v-665484b6] {
  position: fixed !important;
  top: 4.208vw;
  left: 0;
  z-index: 8 !important;
  height: calc(100% - 4.208vw) !important;
  padding-bottom: 5vh;
}
#app .main .body > div:nth-child(2) .earthMap > div[data-v-665484b6] {
  width: 100%;
  height: 100%;
}
#app .main .body > div[data-v-665484b6]:last-child {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  z-index: 9999;
}
#app .main .body > div:last-child > div[data-v-665484b6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-bottom: 1.3889vh;
}
#app .main .body > div:last-child > div[data-v-665484b6]:last-child {
  margin-bottom: 0;
}
#app .rainBox[data-v-665484b6] {
  position: absolute;
  width: 80% !important;
  height: 70% !important;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
}
#app .rainBox canvas[data-v-665484b6] {
  position: absolute;
  bottom: 0;
}
.echartsModelS > .echartsModelS-chart[data-v-665484b6] {
  width: 100%;
  height: calc(100% - 4.1667vh);
  background-image: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@-webkit-keyframes shan-665484b6 {
0% {
    opacity: 1;
}
50% {
    opacity: 0.5;
}
100% {
    opacity: 1;
}
}
@keyframes shan-665484b6 {
0% {
    opacity: 1;
}
50% {
    opacity: 0.5;
}
100% {
    opacity: 1;
}
}
.elItem[data-v-665484b6] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 47%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.elItemImageBox[data-v-665484b6] {
  width: 40%;
}
.elItemImageBox img[data-v-665484b6] {
  width: 100%;
  height: 100%;
}
.LBtxt[data-v-665484b6] {
  width: 55%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.pojList[data-v-665484b6] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1vh;
  z-index: 9;
}
.hideBox[data-v-665484b6] {
  height: 91%;
  width: 17.1979vw;
  background-image: url(../../static/img/pojListBG.1f5810c3.png);
  background-size: 100% 100%;
  float: right;
  margin-top: 9vh;
  padding: 1vh;
}
.hideBox > div[data-v-665484b6]:last-child {
  height: 95%;
  overflow: scroll;
  overflow-x: hidden;
}
.hideBox > div[data-v-665484b6]:last-child::-webkit-scrollbar {
  width: 4px;
}
.scroll[data-v-665484b6] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0;
}
.scroll > span[data-v-665484b6] {
  width: 16vw;
  padding: 1vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.5vh;
  color: white !important;
  font-weight: 400 !important;
}
.scroll > span img[data-v-665484b6] {
  margin-right: 1vh;
}
.scroll > span[data-v-665484b6]:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALgAAAAmCAYAAAB3X1H0AAAB1klEQVR4nO3doWuVURjH8e/vYpAZTAOLMjFoMpiMBwUF/wAXBmYdc2UwxCBGxWERuUHrTK5ZVnYPyIJgEgaWMTEYZhMcpvsYrlfm5Rp0L/fdHn6feM4bnvAN70mP6PUZYxG4A1wYd2l2CK0C3Sja3H94bMyHD4FbwI0o+jiBwcwOTDXmgLeqMRNFn4fnnZHvngLXgMuO246SKFoF7gP39p8PAz8JPAdOA1eiaHey45kdXBQ9AlCNZ8OzDnAJWAG+ATej6Ec745k14i5wRjVWAESv/wJ4F0Uv253LrBmqMQVsAOui148oUttDmTVJNaaBrdFHplkKUfQVmHbglpoDt9QcuKXmwC01B26pOXBLzYFbag7cUnPglpoDt9QcuKXmwC01B26pOXBLzYFbag7cUnPglpoDt9QcuKXmwC01B26pOXBLzYFbag7cUusA66pxve1BzJqkGjPApw7QBd6oxu12RzJrhmpcBd4DXf3a8HCRwUaH78ByFI1d+2B22KnGEvAYmI2iteE/+AdgCTgOvFaNE20NaPY/VGNKNV4B54DzUbQGfz4y94AFYBvYUI1Tkx/T7N+pxllgE/gSRfNRtP377i9LqB4w+GVx5HYU7DBYQPVk9OIndJFpihmDpaYAAAAASUVORK5CYII=);
  background-size: 100% 100%;
  color: #1ed2ff !important;
}
.scroll > span:hover > .oneChk[data-v-665484b6] {
  display: none;
}
.scroll > span:hover > .twoChk[data-v-665484b6] {
  display: block !important;
}
[data-v-665484b6] .el-input--small {
  margin: 0.5vh 0;
}
[data-v-665484b6] .el-carousel__item {
  font-size: 0.729vw;
  padding: 1.5vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
[data-v-665484b6] .el-input__inner {
  color: #f1f1f1;
}
.loadshow[data-v-665484b6] {
  width: 100% !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: end !important;
}
.hertaFont[data-v-665484b6] {
  background-image: url(../../static/img/BTNIMG.5418c52d.png);
  background-size: 100% 100%;
  padding: 6px 18px;
}
.herdStyle[data-v-665484b6] {
  color: #5ad8eb;
  font-size: 2vh;
}
.pojImg[data-v-665484b6] {
  width: 20px;
}
.avueinputTree[data-v-665484b6] {
  width: 50% !important;
}
.Nodataavailableatthemoment[data-v-665484b6] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
}
.Nodataavailableatthemoment img[data-v-665484b6] {
  height: 100px;
}
.dian2[data-v-665484b6] {
  display: inline-block;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 限制在两行 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}
.dian1[data-v-665484b6] {
  display: inline-block;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* 限制在两行 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}
.echartsModelS[data-v-665484b6] {
  width: 100%;
  height: 33.3333%;
  margin-bottom: 0 !important;
}
.el-carousel[data-v-665484b6] {
  height: 100%;
}
.listBoxNew[data-v-665484b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.726vw;
  color: #CDD6e3;
  padding: 10px;
}
.listBoxNew > div[data-v-665484b6] {
  width: 33.3333%;
  height: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.listBoxNew > div .listBox-title[data-v-665484b6] {
  height: 20%;
  text-align: center;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.listBoxNew > div .itemS[data-v-665484b6],
.listBoxNew > div .itemL[data-v-665484b6] {
  height: 40%;
  background-image: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.listBoxNew > div .itemS img[data-v-665484b6],
.listBoxNew > div .itemL img[data-v-665484b6] {
  height: 55%;
}
.listBoxNew > div .itemS span[data-v-665484b6],
.listBoxNew > div .itemL span[data-v-665484b6] {
  width: 50%;
  height: 50%;
  font-size: 0.625vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}